.navbar {
  // background-color: #05071C;
  background-color: $theme-color;
  box-shadow: 0 2px 4px rgb(5, 7, 28);
  .navbar-nav {
    .nav-link {
      text-align: center;
      &.active {
        color: rgb(139, 218, 134);
      }
    }
    a {
      font-size: 16px;
      font-weight: 600;
      color: #FFFFFF;
      margin: 4px 8px;
    }
    .navbar-toggler-icon {
      color: #FFFFFF;
    }    
  }
  .navbar-toggler {
    .navbar-toggler-icon {
      color: #FFFFFF;
    }
  }
  .navbar-collapse {
    flex-grow: 0;
  }
}

@media (max-width: 980px) {
  .navbar-brand img {
      width: 70vw;
  }
  .navbar-nav {
    a {
      font-size: 16px;
      font-weight: 500;
      color: #FFFFFF;
      margin: 4px 8px;
    }
  }
}

@media (max-width: 768px) {
  .navbar-brand img {
      width: 60vw;
  }
  .navbar-nav {
    a {
      font-size: 14px;
      font-weight: 500;
      color: #FFFFFF;
      margin: 4px 8px;
    }
  }
}

.logout-yes-btn {
  width: 20%;
  background-color: rgb(8, 99, 8);
  border: none;
  &:hover {
    background-color: $theme-color;
  }
}

.logout-no-btn {
  width: 20%;
  background-color: rgb(193, 60, 60);
  border: none;
  &:hover {
    background-color: $theme-color;
  }
}