
.edit-pswd-icon {
  position: absolute;
  right: 10px;
  top: 20px;
  cursor: pointer;
}

.member-profile-div {
  padding: 20px 0px;
  width: 100%;
  border-radius: 100%;
  justify-content: center;
  .profile-img {
    display: flex;
    justify-content: center;
    align-items: center;
    @media (min-width: 250px) and (max-width: 767px ) {
      justify-content: center;
    }
  }
  .u-img {
    position: relative;
    width: 150px;
    height: 150px;
    margin: auto;
    .profile-img {
      height: 100%;
      width: 100%;
      border-radius: 100%;
      object-fit: cover;
      box-shadow: 0 0 5px 2px rgba(153, 147, 147, 0.352);
    }
    .upload-pic-icon {
      display: flex;
      align-items: center;
      border-radius: 100%;
      color: #7E8B96;
      font-size: 18px;
      padding: 4px;
      margin: 0;
      position: absolute;
      bottom: 2px;
      right: 14px;
      background-color: #f5f5f5;
      box-shadow: 0 0 5px 2px rgba(153, 147, 147, 0.352);
      cursor: pointer;
    }
  }
  .member-profile-data {
    display: flex;
    justify-content: center;
    align-items: center;
    @media (min-width: 250px) and (max-width:767px) {
      justify-content: center;
      text-align: center;
    }
    .member-data {
      color: gray;
    }
    span { font-weight: 600; }
  }
  @media (max-width: 768px) {
    .desktop-only {
      display: none !important;
    }
  }
}

.mobile-only {
  display: none !important;
  @media (max-width: 768px) {
    display: block !important;
  }
}

.tabs-container {
  @media (min-width: 767px) {
    justify-content: center;
  }
  // overflow-x: auto;
  // white-space: nowrap;
  gap: 14px;
  margin-bottom: 0px;
  padding: 0px 10px;
  // scrollbar-width: none;
  // scroll-snap-type: x mandatory;
  // &::-webkit-scrollbar {
  //   display: none;
  // }
  .nav-tabs {
    display: flex;
    justify-content: center;
    // flex-wrap: nowrap;
    // white-space: nowrap;
    border: none;
    .nav-link {
      border: 1px solid #8ca0b1;
      background-color: #8ca0b1;
      color: black;
      margin-bottom: 15px;
      font-size: 16px;
      border-radius: 5px;
      text-align: center;
      text-decoration: none;
      &.active {
        color: #FFFFFF;
        background-color: $theme-color;
      }
      &:hover {
        // color: #16ac16;
      }
    }
  }
  .nav-item {
    flex: 0 0 auto;
    text-align: center;
    min-width: 120px;
    padding-right: 10px;
    scroll-snap-align: start;
  }
}

.profile-container {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.profile-pic {
  width: 100px;
  height: 100px;
}

.nav-link {
  color: #041025;
  margin-bottom: 15px;
  font-size: 16px;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  &.active {
    color: #16ac16;
  }
  &:hover {
    color: #16ac16;
  }
}

.edit-profile-btn {
  color: #041025;
  &:hover {
    border-radius: 5px;
    padding: 2px 10px;
    background-color: #041025;
    color: #ffffff;
  }
}