.managing-committee {
  text-align: center;
  padding: 20px;

  .title {
    font-size: 24px;
    font-weight: bold;
    margin: 25px 0 15px;
    text-transform: uppercase;
    letter-spacing: 5px;
  }

  @media (min-width: 250px) and (max-width: 768px ) {
    .title {
      font-size: 18px;
    }
  }

  .member {
    margin: 10px 0;
    img {
      border-radius: 50%;
      width: 150px;
      height: 150px;
      object-fit: cover;
      object-position: top;
    }

    .name {
      color: $theme-color;
      margin-top: 10px;
      margin-bottom: 0px;
      font-size: 16px;
      font-weight: bold;
    }

    p {
      color: #16ac16;
      font-size: 14px;
    }
  }

  @media (min-width: 768px) and (max-width: 992px) {
    .member {
      display: inline-block;
      width: calc(33.333% - 20px);
      vertical-align: top;
    }
  }
}
