.login-form {
  padding: 1rem;
  // background: #f7f7f7;
  // border-radius: 5px;
  // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  .form-group {
    margin-bottom: 1rem;
  }

  .otp-display {
    margin-top: 0.5rem;
    color: green;
  }
  .reset-pass-text {
    padding: 20px 10px;
  }
}


.login-register-card {
  background-color: #ffffff;
  border-radius: 20px;
  margin: 25px 0px;
  box-shadow: 0 0 5px 2px rgba(153, 147, 147, 0.352);
  &.login-height {
    height: 500px;
    overflow-y: auto;
    scrollbar-width: none;
  }
  .user-profile-btn {
    background-color: transparent;
    color: $theme-color;
    border: 1px solid $theme-color;
  }
  .user-profile-update-btn {
    &:hover {
      background-color: $theme-color;
      color: #ffffff;
      border: none;
      transition: .3s ease-in-out;
      transform: scale(1.1);
    }
  }
  .user-profile-cancel-btn {
    &:hover {
      transition: .3s ease-in-out;
      transform: scale(1.1);
    }
  }
  .login-title {
    text-align: center;
    margin-top: 20px;
    font-weight: 700;
    font-size: 26px;
    color: $theme-color;
    &:hover {
      color: #383e80;
    }
  }
  .login-register-btn {
    padding: 10px;
    background-color: $theme-color;
    border: 1px solid $theme-color;
    &:hover {
      background-color: #232a8a;
      transition: .3s ease-in-out;
      transform: scale(1.1);
    }
  }
  .forgot-text {
    text-align: end;
    margin: 20px 20px 0;
    color: $theme-color;
    &:hover {
      text-decoration: underline;
    }
  }
  .register-text {
    text-align: center;
    margin: 20px;
    color: $theme-color;
    &:hover {
      color: #ad1d40;
      text-decoration: underline;
    }
  }
  .announcement-title {
    margin: 40px 0 0;
  }
  .announcement-text {
    color: #0067b8;
    &:hover {
      text-decoration: underline;
      color: $theme-color;
    }
  }
  .more-text {
    &:hover {
      text-decoration: underline;
      color: green;
    }
  }
}

.blinking-text {
  animation: blinkingText 0.8s infinite;
}

@keyframes blinkingText {
  0% {
    color: #000;
  }
  49% {
    color: #0a8816;
  }
  99% {
    color: #ab203a;
  }
  100% {
    color: #000;
  }
}

.register-btn {
  background-color: $theme-color;
  border: 1px solid $theme-color;
  width: 100%;
  &:hover {
    background-color: $theme-color;
  }
}

.custom-update {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #333333;
  border: 1px solid #333333;
  &:hover {
    background-color: #333333;
  }
}

.students {
  height: 400px;
}

.password-reset-text {
  height: 400px;
  margin: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  .return-text {
    color: #ad1d40;
    &:hover {
      color: #333333;
    }
  }
}

.resend-otp-btn {
  border: none;
  padding: 10px 0 0;
}

.profile-menu {
  list-style: none;
  .nav-link {
    text-align: end;
    padding: 0px;
    margin: 2px 0px;
    font-weight: 500;
    &.active {
      color: green;
      font-weight: bolder;
    }
  }
}