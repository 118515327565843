$primary-color: #041025;
$secondary-color: #5a67d8;

.download-container {
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 50px;

  h1 {
    color: $primary-color;
    font-size: 2.5rem;
    margin-bottom: 20px;
    text-transform: uppercase;
    text-align: center;
  }

  p {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 20px;
    text-align: justify;
  }

  .btn-download {
    background-color: $secondary-color;
    color: white;
    font-size: 1rem;
    padding: 10px 20px;
    border-radius: 30px;
    transition: background-color 0.3s ease-in-out;

    &:hover {
      background-color: darken($secondary-color, 10%);
    }
  }

  .countdown-text {
    margin-top: 20px;
    color: $primary-color;
    font-weight: bold;
  }

  .back-btn {
    margin-top: 15px;
    background-color: $primary-color;
    border: none;
    font-size: 1rem;
    padding: 10px 30px;
    border-radius: 30px;
    color: white;
    transition: background-color 0.3s ease-in-out;

    &:hover {
      background-color: lighten($primary-color, 10%);
    }
  }
}
