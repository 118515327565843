.newsletters {
  // padding: 20px;
}

hr {
  border: 0;
  border-bottom: 1px solid #544d4d7f;
  margin: 10px auto
}

.newsletter-grid {
  margin: 15px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  @media (min-width: 250px) and (max-width:768px) {
    display: flex;
    justify-content: center;
  }
  .newsletter-card {
    cursor: pointer;
    width: 100%;
    .card-img-top {
      height: 300px;
    }
    @media (min-width: 992px) and (max-width:1100px) {
      width: 100vw;
    }
    @media (min-width: 768px) and (max-width:991px) {
      width: 60vw;
    }
    @media (min-width: 450px) and (max-width:767px) {
      width: 60vw;
    }
    @media (min-width: 250px) and (max-width:449px) {
      width: 70vw;
    }
  }
}