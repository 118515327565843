.carousel-container {
  position: relative;
  // width: 100%;
  // height: 100%;
  display: flex;
  justify-content: center;
  margin: 30px 0;
}

.carousel-skeleton {
  width: 90%;
  height: 90%;
}

.carousel-skeleton > span {
  margin-bottom: 16px;
}
