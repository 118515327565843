.launch-popup {
  overflow: visible;
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../images/fireworks.gif') no-repeat center center;
  background-size: cover;
  font-size: 30px;
  animation: pulse 4s ease-in-out infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.launch-btn {
  background: transparent;
  border: none;
  font-size: 30px;
  padding: 30px 50px;
  animation: pulse 2s infinite;
  &:hover {
    background: transparent;
  }
}
