.upcoming-events {
  padding: 20px;
  color: $theme-color;
}

.event-title {
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
}

.card-text {
  text-align: center;
  span {
    font-weight: 500;
  }
  .faculty-name, .coordinator-name, .event-time {
    font-weight: 700;
  }
  .event-time {
    color: #16ac16;
    padding: 10px;
  }
}

.card-img-top {
  height: 250px;
}

.btn-register-event {
  color: #16ac16;
  color: #ffffff;
  border: none;
  &:hover {
    color: #ffffff;
    background-color: green;
  }
}

.no-event-data {
  img {
    height: auto;
    width: 100vw;
  }
}

.events-not-available {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.events-details-card {
  padding: 30px 60px;
  @media (min-width: 250px) and (max-width: 768px ) {
    padding: 30px 20px;
    margin-top: 30px;
  }
  .event-details-txt {
    padding: 0px 30px;
    .event-title {
      color: $theme-color;
      font-size: 20px;
      padding-bottom: 30px;
    }
    .event-time {
      color: green;
      font-size: 18px;
    }
    .register-fees-txt {
      color: green;
    }
    .note-txt {
      color: red;
      // padding-top: 20px;
    }
    .event-description {
      text-align: center;
    }
    .material-download-btn {
      margin: 15px;
      background-color: $theme-color;
      border: 1px solid $theme-color;
    }
  }
  .event-register-btn {
    padding: 10px;
    background-color: $theme-color;
    border: 1px solid $theme-color;
  }
}

.modal-footer {
  .modal-event-register-btn {
    padding: 10px;
    background-color: $theme-color;
    border: 1px solid $theme-color;
  }
}

.text-vertical-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
}
