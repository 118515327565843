.gallery {
  // padding: 20px;
  .gallery-grid {
    height: 100%;
  }
}

hr {
  border: 0;
  border-bottom: 1px solid #544d4d7f;
  margin: 10px auto
}

.image-grid {
  margin: 15px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  @media (min-width: 250px) and (max-width:768px) {
    display: flex;
    justify-content: center;
  }
  .img-thumbnail {
    cursor: pointer;
    width: 100%;
    height: 300px;
    @media (min-width: 992px) and (max-width:1100px) {
      width: 100vw;
      height: 250px;
    }
    @media (min-width: 768px) and (max-width:991px) {
      width: 60vw;
      height: 200px;
    }
    @media (min-width: 450px) and (max-width:767px) {
      width: 60vw;
      height: 230px;
    }
    @media (min-width: 250px) and (max-width:449px) {
      width: 70vw;
      height: 260px;
    }
  }
}


.gallery-modal-dialog {
  width: 100%;
  .modal-dialog {
    // width: auto;
    // max-width: 90%;
  }
  .modal-content {
    background: none;
    border: none;
  }
}

.gallery-modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  img {
    max-width: 100%;
    max-height: 85vh;
  }
}


// .gallery-modal-dialog {
//   .modal-dialog {
//     max-width: 90%;
//   }
//   .modal-content {
//     background: none;
//     border: none;
//   }
// }

.custom-backdrop {
  background-color: rgba(0, 0, 0, 0.7); // Adjust the opacity and color as needed
}

// .gallery-modal-body {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   padding: 0;
//   .img-fluid {
//     max-width: 100%;
//     height: 85vh;
//     @media (min-width: 250px) and (max-width:768px) {
//       width: 100vw;
//       height: 300px;
//     }
//   }
// }

.gallery-modal-header {
  border: none;
  .close-icon {
    position: absolute;
    top: -20px;
    right: -20px; 
    color: #fff;
    font-size: 24px;
    cursor: pointer;
  }
}

.card-title {
  text-align: center;
}