footer {
  background-color: $theme-color;
  color: white;
}

.footer-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
  @media (min-width: 250px) and (max-width:449px) {
    margin-top: 20px;
  }
}

.contact-us-list {
  padding: 0px;
  li {
    list-style: none;
    padding: 0px;
    margin: 0px;
    a {
      text-decoration: none;
      color: #ffffff;
    }
    p {
      margin: 0px;
      padding: 10px;
    }
  }
}

.footer-form-class {
  .form-control {
    background-color: transparent;
    color: white;
    border-color: white;
    margin: 10px 0px;
    &:focus {
      background-color: transparent;
      color: white;
      border-color: white;
      box-shadow: none;
    }
    &::placeholder {
      color: #ffffff;
    }
  }
}

.footer-submit-btn {
  width: 100%;
  background-color: transparent;
  color: #ffffff;
  border-color: #ffffff;
  &:hover {
    background-color: #689726;
    border-color: #689726;
  }
}

a {
  text-decoration: none;
}

.social-media-icons {
  padding: 10px 0px;
  a {
    margin-right: 10px;
  }
}

.copy-right-text {
  @media (min-width: 250px) and (max-width:950px) {
    margin: 30px 10px 10px;
  }
}