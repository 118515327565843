.toast-container {
  position: fixed;
  top: 50px;
  right: 20px;
  .toast-header .btn-close {
    position: absolute;
    right: 20px;
  }
}

.bg-danger {
  background-color: #FFF5F3 !important;
  color: rgb(102, 65, 56) !important;
}
.bg-success {
  background-color: #F6FDF2 !important;
  color: 446032 !important;
}