.common-title {
  font-size: 24px;
  font-weight: bold;
  margin: 25px 0 15px;
  text-transform: uppercase;
  letter-spacing: 5px;
  text-align: center;
}

@media (min-width: 250px) and (max-width: 768px ) {
  .common-title {
    font-size: 18px;
  }
}

.equal-height {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.card {
  background-color: #f5f5f5;
  color: #333333;
  box-shadow: 0 0 5px 2px rgba(153, 147, 147, 0.352);
  .card-body {
    color: #333333;
  }
}

.card-img-top {
  padding: 15px;
  transition: .3s ease-in-out;
  transform: scale(1);
}

.card .card-img-top:hover {
  transform: scale(1.10);
}

.card-title {
  color: #333333;
  font-size: 16px;
  font-weight: 900;
  // height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    color: #16ac16;
  }
}

.m-header-no-border {
  border: none;
}

.cursor-pointer {
  cursor: pointer;
}