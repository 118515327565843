.popup {
  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    animation: fadeIn 0.3s ease;
  }

  &-container {
    width: 90%;
    max-width: 500px;
    max-height: 80vh;
    position: relative;
    animation: scaleIn 0.4s ease;
    display: flex;
    flex-direction: column;
  }

  &-content {
    background-color: #fff;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    max-height: 100%;
  }

  &-close {
    position: absolute;
    top: 10px;
    right: 15px;
    background: rgba(255, 255, 255, 0.9);
    border: none;
    font-size: 24px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1;
    transition: all 0.2s;
    color: #333;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

    &:hover {
      transform: scale(1.1);
      background: #fff;
    }
  }

  &-header {
    padding: 15px 20px;
    border-bottom: 1px solid #f0f0f0;
    text-align: center;
    
    h3 {
      margin: 0;
      font-size: 1.5rem;
      color: #333;
      font-weight: 600;
    }
  }

  &-body {
    padding: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &-image-container {
    width: 100%;
    flex: 0 0 auto;
    overflow: hidden;
  }

  &-image {
    width: 100%;
    height: auto;
    display: block;
    object-fit: contain;
    max-height: 50vh;
  }

  &-text {
    padding: 20px;
    text-align: center;
    
    h4 {
      margin-top: 0;
      color: #0066cc;
      font-weight: 600;
      font-size: 1.2rem;
    }
    
    p {
      color: #555;
      margin-bottom: 0;
    }
  }

  &-footer {
    padding: 20px;
    text-align: center;
    border-top: 1px solid #f0f0f0;
  }

  &-button {
    min-width: 150px;
    padding: 10px 20px;
    font-weight: 600;
    background-color: #0066cc;
    border: none;
    border-radius: 30px;
    color: white;
    cursor: pointer;
    transition: all 0.3s;
    box-shadow: 0 2px 5px rgba(0, 102, 204, 0.3);
    
    &:hover {
      background-color: #0055aa;
      transform: translateY(-2px);
      box-shadow: 0 4px 8px rgba(0, 102, 204, 0.4);
    }
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes scaleIn {
  from { transform: scale(0.8); opacity: 0; }
  to { transform: scale(1); opacity: 1; }
}

@media (max-width: 576px) {
  .popup {
    &-container {
      width: 95%;
    }
    
    &-header h3 {
      font-size: 1.3rem;
    }
    
    &-text h4 {
      font-size: 1.1rem;
    }
  }
}
