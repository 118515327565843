.content-page {
  background-color: transparent;
  margin: 20px 0px;
  padding: 50px;
  border-radius: 20px;
  @media (min-width: 250px) and (max-width:449px) {
    margin: 20px 10px;
    padding: 20px 10px;
  }
  .profile-card-content {
    border-radius: 5px;
    text-align: center;
    img {
      max-width: 300px;
      height: 300px;
    }
    color: $theme-color;
    .name {
      font-size: 18px;
      font-weight: 800;
      @media (min-width: 250px) and (max-width:449px) {
        font-size: 16px;
      }
    }
    .designation, .subtitle {
      font-size: 16px;
      font-weight: 600;
      @media (min-width: 250px) and (max-width:449px) {
        font-size: 14px;
      }
    }
  }
  .policy-content:not(:last-child) {
    margin-bottom: 70px;
  }  
}

.title, .signature {
  color: #333333;
  @media (min-width: 250px) and (max-width:449px) {
    font-size: 14px;
  }
}

.content {
  margin-top: 20px;
  text-align: justify;
  h5 {
    font-weight: bold;
    padding-top: 30px;
  }
  @media (min-width: 250px) and (max-width:449px) {
    font-size: 12px;
  }
}

.title {
  font-weight: 800;
}

.signature {
  margin-top: 40px;
  text-align: end;
  font-weight: 900;
  p {
    margin: 0;
  }
}

.president-image {
  width: 150px;
  height: auto;
  margin-right: 20px;
}

.signature-text {
  font-size: 1.2em;
  font-weight: bold;
}
