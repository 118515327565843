.payment-table {
  .payment-title {
    text-align: center;
    margin-top: 20px;
    font-weight: 700;
    font-size: 26px;
    color: $theme-color;
    &:hover {
      color: #383e80;
    }
  }
  .custom-payment-txt {
    color: green;
  }

  .table-responsive {
    overflow-x: auto;
  }
  
  .responsive-table {
    width: 100%;
    table-layout: auto;
  }
}