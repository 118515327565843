.profile-card {
  // padding: 20px;
  background-color: #efeff3e9;
  color: #333333;
  margin: 30px 0px;
  @media (min-width: 250px) and (max-width:768px) {
    margin: 30px;
  }
  border-radius: 20px;
  text-align: center;

  img {
    // max-width: 300px;
    height: 300px;
    // margin-bottom: 15px;
  }

  .title {
    color: #333333;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .name {
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    margin-bottom: 10px;
  }

  .read-message {
    cursor: pointer;
    text-decoration: none;
    color: #16ac16;
    font-weight: 500
  }
}
