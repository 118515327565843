.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  // scrollbar-width: none;
}

.table {
  width: 100%;
  min-width: 600px;
  tr {
    // cursor: pointer;
    th {
      font-weight: bold;
    }
  }
  img {
    max-width: 100px;
    height: auto;
  }
}